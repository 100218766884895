import React from "react";

const Footer = () => {
    return (
        <footer className="py-2 text-center text-gray-800 text-sm px-4 md:px-16">
            &copy; 2021 Passoladro S.r.l. - P. IVA 02011260896 - Cap. sociale
            10.000 € -&nbsp;
            <a
                href="mailto:info@passoladro.it"
                className="underline"
            >
                info@passoladro.it
            </a>
            &nbsp;-&nbsp;
            <a
                href="https://passoladro.it/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
            >
                Privacy Policy
            </a>
        </footer>
    );
};

export default Footer;
