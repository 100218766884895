import React from "react";
import SEO from "./SEO";
// import { Link } from "gatsby";
import Logo from "../components/Logo";
import Footer from "./Footer";

const Layout = ({ children }) => {
    return (
        <>
            <SEO />
            <div className="flex flex-col">
                <header className="flex align-middle justify-center py-3 mb-1 md:mb-10 text-white bg-black">
                    <div className="mx-auto">
                        <a
                            href="https://passoladro.it/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Logo dark />
                        </a>
                    </div>
                    {/* <div className="max-w-4xl mx-auto">
                    <Link to="/iscriviti">
                        <h2 className="max-w-4xl text-2xl mx-auto uppercase font-bold underline">Iscriviti</h2>
                    </Link>
                </div> */}
                </header>
                {children}
                <Footer />
            </div>
        </>
    );
};

export default Layout;
